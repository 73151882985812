import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import axios from 'axios';
import { baseURL } from '../../../config/config';

// COMPONENTES
import Tabla from '../../../components/Tabla';

// ICONOS
import { IoIosAddCircle } from "react-icons/io";
import { MdDeleteForever } from "react-icons/md";

const ModuloEgresos = ({ idOrden }) => {

    // ===============================================
    // COLUMNAS PARA LA TABLA
    // ===============================================

    const columns = [

        {
            name: 'Concepto',
            selector: row => row.concepto,
            sortable: true,
            width: '45%',
        },
        {
            name: 'Monto',
            selector: row => `$ ${row.monto}`,
            sortable: true,
            width: '20%',
        },
        {
            name: 'Moneda',
            selector: row => row.moneda,
            sortable: true,
            width: '20%',
        },
        {
            name: '',
            sortable: true,
            width: '15%',
            cell: (row) =>

                <button 
                    className="btn btn-outline-danger" 
                    type="button"  
                    onClick={() => deleteIngreso(row.id, row.rel_orden)}
                >
                    <MdDeleteForever/>
                </button>
        },

    ];

    // ===============================================
    // FUNCION PARA ELIMINAR INGRESO
    // ===============================================

    const deleteIngreso = ( idIngreso, idOrder ) => {

        Swal.fire({
            icon: 'warning',
            title: 'Desea eliminar este ingreso ?',
            confirmButtonText: "Eliminar",
            showCancelButton: true,
        })
        .then((result) => {

            if(result.isConfirmed){

                axios.delete(`${baseURL}api/ordenes/logistica/delete/egreso/${idIngreso}`,{
  
                    headers: {
                        "access-token": Cookies.get('jwtoken')
                    } 
            
                })
                .then(result => {
        
                    if(result.data.success === true)
                    {
                
                        buscarEgresos(idOrder);
                
                    }
            
                })
                .catch(error => {
            
                    console.log(error)
            
                })

            }

        })

    }

    // ===============================================
    // FUNCION PARA BUSCAR EGRESOS
    // ===============================================

    const [data, setData] = useState([])

    const buscarEgresos = async (id) => {

        const config = {
    
            headers: {"access-token": Cookies.get('jwtoken')},
    
        };

        const response = await axios.get(`${baseURL}api/get/ordenes/logistica/egresos/${id}`, config);

        if(response.data.success === true && response.data.result !== "Sin resultados"){

            setData(response.data.result);

        }else{

            setData([]);

        }

    }

    // ===============================================
    // ESTADO PARA FORMULARIO
    // ===============================================

    const [formData, setFormData] = useState({

        concepto: "",
        monto: "",
        moneda: ""

    })

    // ===============================================
    // FUNCION PARA ACTUALIZAR FORMULARIO
    // ===============================================

    const handleChange = (event) => {

        const val = event.target.value;
        const name = event.target.name;

        setFormData({ ...formData, [name]: val })

    }

    useEffect(() => {

        buscarEgresos(idOrden);

    },[idOrden]);

    // ===============================================
    // SUBMIT DEL FORMULARIO
    // ===============================================

    const handleSubmit = () => {

        // VALIDAMOS QUE EL FORMULARIO NO ESTE VACIO

        if(formData.concepto !== "" && formData.moneda !== "" && formData.monto !== ""){
            
            console.log(formData, idOrden)

            formData.rel_orden = idOrden;

            const urlCreate = baseURL + "api/ordenes/logistica/crear/egreso";

            axios.post(urlCreate, formData,{
  
                headers: {
                    "access-token": Cookies.get('jwtoken')
                } 
        
            })
            .then(result => {
        
                if(result.data.success === true)
                {
            
                    buscarEgresos(idOrden);

                    setFormData({

                        concepto: "",
                        monto: "",
                        moneda: ""
                
                    })

                    document.getElementById('conceptoEgresosLogistica').value = "";
                    document.getElementById('montoEgresosLogistica').value = "";
                    document.getElementById('monedaEgresosLogistica').value = "";
            
                }
        
            })
            .catch(error => {
        
                console.log(error)
        
            })

        } else {

            Swal.fire({
                icon: 'warning',
                title: 'Complete los campos para continuar!',
            })

        }

    }

    // ===============================================

    return (

        <div>

            <h5 className='mb-4 text-center'>Egresos</h5>

            <div className='row'>

                {/* CONCEPTO */}

                <div className='col-5'>

                    <span className="badge text-bg-secondary mb-2">Concepto:</span>

                    <select
                        name="concepto"
                        className="form-select"
                        onChange={(event) => handleChange(event) }
                        id="conceptoEgresosLogistica"
                    >

                        <option value="">Seleccione Concepto</option>
                        <option value="Cargo Por Retraso (Carrier)">Cargo Por Retraso (Carrier)</option>
                        <option value="Cruce">Cruce</option>
                        <option value="Descarga">Descarga</option>
                        <option value="Demora">Demora</option>
                        <option value="Estancia">Estancia</option>
                        <option value="Lavado">Lavado</option>
                        <option value="Movimiento En Falso">Movimiento En Falso</option>
                        
                        
                    </select>

                </div>

                {/* MONTO */}

                <div className='col-3'>

                    <span 
                        className="badge text-bg-secondary mb-2"
                    >
                        $ Monto:
                    </span>

                    <input
                        className='form-control'
                        type="number"
                        name="monto"
                        placeholder="Monto"
                        step=".05"
                        onChange={(event) => handleChange(event) }
                        id="montoEgresosLogistica"
                    />

                </div>

                {/* MONEDA */}

                <div className='col-3'>

                    <span className="badge text-bg-secondary mb-2">Moneda:</span>

                    <select
                        name="moneda"
                        className="form-select"
                        onChange={(event) => handleChange(event) }
                        id="monedaEgresosLogistica"
                    >

                        <option value="">Moneda</option>
                        <option value="MXN">MXN</option>
                        <option value="USA">USA</option>
                        
                        
                    </select>

                </div>

                {/* AGREGAR INGRESO */}

                <div className='col-1 d-flex justify-content-center align-items-end'>

                    <button 
                        type="button" 
                        className="btn btn-primary"
                        onClick={handleSubmit}
                    >
                        <IoIosAddCircle/>
                    </button>

                </div>

                {/* TABLA DE INGRESOS */}

                <div className='col-12 mt-4'>

                    <Tabla columns={columns} data={data} />

                </div>

            </div>

        </div>

    )

}

export default ModuloEgresos