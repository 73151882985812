import React from 'react';

// ICONOS
import {AiOutlineCloudDownload} from 'react-icons/ai';

// COMPONENTES
import ModuloIngresos from './IngresosEgresos/ModuloIngresos';
import ModuloEgresos from './IngresosEgresos/ModuloEgresos';

const ModalIngresosEgresos = ({ idOrden }) => {

    // ===============================================
    // FUNCION PARA FORMATEAR ID DE ORDEN
    // ===============================================

    const lpad = (str, length, padChar) => {

        str = String(str);

        while (str.length < length) {

          str = padChar + str;

        }

        return str;

    }

    // ===============================================

    return (

        <div 
            className="modal fade" 
            id="modalIngresosEgresosL"  
            aria-labelledby="exampleModalLabel" 
            aria-hidden="true" 
            data-bs-backdrop="static"
        >

            <div 
                className="modal-dialog modal-xl" 
                style={{"maxHeight":"800px", "height":"600px"}}
            >

                <div className="modal-content">

                    <div className="modal-header">

                        <h5 
                            className="modal-title" 
                            id="exampleModalLabel"
                        >
                            Orden L-{lpad(idOrden, 5, "0")}
                        </h5>
                        
                        <button 
                            type="button" 
                            className="btn-close" 
                            data-bs-dismiss="modal" 
                            aria-label="Close"
                        ></button>

                    </div>

                    {/* BODY DEL MODAL */}

                    <div className="modal-body">

                        <div className='row'>

                            {/* INGRESOS */}

                            <div className='col-6'>

                                <ModuloIngresos idOrden={idOrden} />

                            </div>

                            {/* EGRESOS */}

                            <div className='col-6'>

                                <ModuloEgresos idOrden={idOrden} />

                            </div>

                        </div>

                    </div>

                    <div className="modal-footer">

                        <button 
                            type="button" 
                            className="btn btn-primary"
                        >
                            Cerrar
                        </button>

                    </div>

                </div>
                
            </div>
            
        </div>

    )

}

export default ModalIngresosEgresos