import React, { useState } from 'react';
import Tabla from '../../components/Tabla';
import Swal from 'sweetalert2';
import axios from 'axios';
import Cookies from 'js-cookie';
import { baseURL } from '../../config/config';

// ICONOS
import {MdOutlineAddCircle} from 'react-icons/md';
import {RiDeleteBinFill} from 'react-icons/ri';

// COMPONENTES
import ModalAgregarCajaActiva from './ModalAgregarCajaActiva';

const VistaCrud = ({ data, refresh, catCajas, catTractores, catClientes }) => {

    const [formValues, setFormValues] = useState({

        vehicleName: "",
        placa: "",
        tractorName: "",
        placaTractor: "",
        rel_cliente: "",
    });

    // ===============================================
    // COLUMNAS PARA LA TABLA DE CAJAS
    // ===============================================

    const columns = [
        {
            name: 'Cliente',
            selector: row => row.nombre_comercial,
            sortable: true
        },
        {
            name: 'Termo',
            selector: row => row.vehicleName,
            sortable: true
        },
        {
            name: 'Placas',
            selector: row => row.placa,
            sortable: true
        },
        {
            name: 'Tractor',
            selector: row => row.tractorName,
            sortable: true
        },
        {
            name: 'Placas Tractor',
            selector: row => row.placaTractor,
            sortable: true
        },
        {
            name: "",
            button: true,
            cell: (row) => (
              
                <div className="input-group">
                  <button className="btn btn-outline-danger" type="button" onClick={() => handleDelete(row.id)}><RiDeleteBinFill/></button>
                </div>
             
            ),
        },

    ];

    // ===============================================
    // ACTUALIZAR FORMULARIO
    // ===============================================

    const handleChange = (event) => {

        const val = event.target.value;
        const name = event.target.name;

        const temp = val.split('+'); // [0] = numero de termo / [1] = placa

        if(name === "vehicleName"){

            validarCaja(temp[0], temp[1]);
            
        }

    }
    const handleChange2 = (rowData) => {
        setFormValues({ ...formValues, ["tractorName"]: rowData.numero_economico, ["placaTractor"]: rowData.placa});
    };
    const handleChange3 = (event) => {
        const val = event.target.value;
        const name = event.target.name;
        setFormValues({ ...formValues, [name]: val });
    };
    // ===============================================
    // VALIDAR SI YA ESTA ACTIVA LA CAJA
    // ===============================================

    const validarCaja = async (caja, placas) => {
        
        axios.get(`${baseURL}api/validar/caja/activa/${caja}`,{
        
            method: "GET",
            headers: {"access-token": Cookies.get('jwtoken')}
        
        })
        .then(result => {console.log(result.data)
    
            if(result.data.success === true && result.data.result === "Sin resultados")
            {

                console.log("NO EXISTE")

                setFormValues({ ...formValues, ["vehicleName"]: caja, ["placa"]: placas });

                document.getElementById("placaTermoActivo").value = placas;
                
            }else{

                console.log("SI EXISTE")

                Swal.fire({
                    icon: 'warning',
                    title: 'Esta caja ya esta activa',
                })

                document.getElementById("selectAgregarCajaActiva").value = "";
                document.getElementById("placaTermoActivo").value = ""
                
            }
    
        })
        .catch(error => {
        
            console.log(error)
        
        })

    }

    // ===============================================
    // FUNCION PARA ELIMINAR CAJA
    // ===============================================

    const handleDelete = (id) => {

        const tokeen = Cookies.get('jwtoken');

        Swal.fire({
            title: 'Estas seguro de borrar esta caja?',
            text: "Esta accion no podra ser revertida!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, borrar!'
          }).then((result) => {
            if (result.isConfirmed) {

                console.log(1)

              axios.delete(`${baseURL}api/delete/termo/${id}`,{
        
                headers: {"access-token": tokeen}
            
              })
              .then(result => {

                console.log(2)
          
                if(result.data.success == true)
                {
      
                  Swal.fire({
                    icon: 'success',
                    title: 'Se ha eliminado correctamente!',
                  })
                  .then(() => {
          
                    refresh();
          
                  })
      
                }
          
              })
              .catch(error => {
            
                console.log(error)
            
              })

              console.log(3)
      
            }
            
        })

    }

    return (

        <div className='mt-4'>

            <button
                type='button'
                className='btn btn-primary'
                data-bs-toggle="modal" 
                data-bs-target="#modalAgregarCajaActiva">
                    <MdOutlineAddCircle/> Agregar Caja
            </button>

            {/* TABLA PARA MOSTRAR CAJAS */}

            <Tabla columns={columns} data={data} />

            {/* MODAL PARA AGREGAR CAJAS */}

            <ModalAgregarCajaActiva
                onChange={handleChange}
                onChange2={handleChange2}
                onChange3={handleChange3}
                data = {formValues}
                refresh={refresh}
                catCajas={catCajas}
                catTractores={catTractores}
                catClientes={catClientes}
                totalUnidades={data.length}
            />

        </div>

    )

}

export default VistaCrud