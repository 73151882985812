import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { baseURL } from '../../config/config';

// COMPONENTES
import Tabla from '../../components/Tabla';

const BitacoraDogo = () => {

    const [data, setData] = useState([]);

    const buscarBitacora = () => {

        console.log('Buscando datos bitacora ...')
        
        axios.get(`https://apiebtsuite.elitebordergroup.com/api/get/bitacora/dogo`,{
        
            method: "GET",
            //headers: {"access-token": token}
        
        })
        .then(result => {
    
            if(result.data.success == true)
            {
        
                setData(result.data.result);

            }
    
        })
        .catch(error => {
        
            console.log(error)
        
        })
    
    };

    
    const formatDate = (date) => {

        const utcDate = new Date(date);
        const localDate = utcDate.toLocaleString();
        return localDate;

    }

    const columns = [

        {
            name: 'Unidad',
            selector: row => row.economico,
            sortable: true
        },
        {
            name: 'Tipo',
            selector: row => row.unitType === 1 ? 'Tractor' : 'Caja',
            sortable: true
        },
        {
            name: 'Placas',
            selector: row => row.placa,
            sortable: true
        },
        {
            name: 'Recibido',
            selector: row => row.responseDogo === 1 ? 'Recibido Por Dogo' : 'Error',
            sortable: true
        },
        {
            name: 'Fecha',
            selector: row => formatDate(row.fecha_crea),
            sortable: true
        },

    ];

    useEffect(() => {

        buscarBitacora();

        // Establecer el intervalo de tiempo en 5 minutos (300,000 milisegundos)
        const repetirProceso = setInterval(buscarBitacora, 300000);

        // Limpia el intervalo cuando el componente se desmonte
        return () => clearInterval(repetirProceso);

    },[]);

    return (

        <div className='mt-4'>

            <Tabla columns={columns} data={data} />

        </div>

    )

}

export default BitacoraDogo