import React, { useState, useEffect } from 'react';
import { baseURL } from '../../config/config';

import Cookies from 'js-cookie';
import axios from 'axios';

import VistaCrud from '../../componentsLogistica/cajasActivas/VistaCrud';
import BitacoraDogo from '../../componentsLogistica/cajasActivas/BitacoraDogo';


const CajasActivas = () => {

    const [token, setToken] = useState('');

    // ===============================================
    // DATOS PARA LA TABLA DE CAJAS
    // ===============================================

    const [data, setData] = useState([]);

    const buscarCajas = async () => {

        const config = {
  
            headers: {"access-token": token},
    
        };

        const response = await axios.get(`${baseURL}api/get/termos/datatable`, config);

        if(response.data.success === true && response.data.result !== "Sin resultados"){

            setData(response.data.result); 

        }else{

            setData([]);

        }

    }

    // ===============================================
    // BUSCAR CATALOGO DE CAJAS
    // ===============================================

    const [catCajas, setCatCajas] = useState([])

    const buscarCatalogoCajas = () => {

        setToken(Cookies.get('jwtoken'));
        
        axios.get(`${baseURL}api/get/cajas/logistica`,{
        
            method: "GET",
            headers: {"access-token": token}
        
        })
        .then(result => {
    
            if(result.data.success == true)
            {
                let des = result.data.result;

                des.unshift({id: "", numero: "Seleccione Termo"});
        
                setCatCajas(des);
            }
    
        })
        .catch(error => {
        
            console.log(error)
        
        })
    
    };

    const [catTractores, setTractores] = useState([])
    const buscarCatalogoTractores = () => {

        setToken(Cookies.get('jwtoken'));
        
        axios.get(`${baseURL}api/tractores`,{
        
            method: "GET",
            headers: {"access-token": token}
        
        })
        .then(result => {
    
            if(result.data.success == true)
            {
                let des = result.data.result;

                des.unshift({id: "", numero_economico: "Seleccione Tractor"});
        
                setTractores(des);
            }
    
        })
        .catch(error => {
        
            console.log(error)
        
        })
    
    };

    const [catClientes, setClientes] = useState([])

    const buscarCatalogoClientes = () => {

        setToken(Cookies.get('jwtoken'));
        
        axios.get(`${baseURL}api/clientes`,{
        
            method: "GET",
            headers: {"access-token": token}
        
        })
        .then(result => {
    
            if(result.data.success == true)
            {
                let des = result.data.result;

                des.unshift({id: "", nombre_comercial: "Seleccione Cliente"});
        
                setClientes(des);
            }
    
        })
        .catch(error => {
        
            console.log(error)
        
        })
    
    };

    useEffect(() => {

        setToken(Cookies.get('jwtoken'));

        buscarCajas();

        buscarCatalogoCajas();

        buscarCatalogoTractores();

        buscarCatalogoClientes();

    }, [token]);

    return (

        <div>

            {/* <h3 className='mb-4'>Unidades Activas</h3> */}

            <ul className="nav nav-tabs nav-justified mt-4" id="myTabTractor" role="tablist">

                <li className="nav-item" role="presentation">

                    <button className="nav-link active" id="contact-tab" data-bs-toggle="tab" data-bs-target="#tabCrudUnidadesActivas" type="button" role="tab" aria-controls="contact" aria-selected="false"><p className="h5">Unidades Activas</p></button>

                </li>

                <li className="nav-item" role="presentation">

                    <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#tabBitacoraDogo" type="button" role="tab" aria-controls="contact" aria-selected="false"><p className="h5">Bitacora</p></button>

                </li>

            </ul>

            <div className="tab-content" id="myTabContentClientes">

                <div className="tab-pane fade show active" id="tabCrudUnidadesActivas" role="tabpanel" aria-labelledby="home-tab">

                    <VistaCrud 
                        data={data} 
                        refresh={buscarCajas}
                        catCajas={catCajas}
                        catTractores={catTractores}
                        catClientes={catClientes}
                    />

                </div>

                <div className="tab-pane fade" id="tabBitacoraDogo" role="tabpanel" aria-labelledby="profile-tab">

                    <BitacoraDogo/>

                </div>

            </div>

        </div>

    )

}

export default CajasActivas