import React from 'react'

const DespachoLogistica = () => {

  return (

    <h3 className='mb-4'>Despacho</h3>

  )

}

export default DespachoLogistica